export const UPLOADS_URL = __DEV__
  ? 'http://app.controldejornadalaboral.loc:8080'
  : 'https://app.controldejornadalaboral.es';

export const API_URL = __DEV__
  ? 'http://api.controldejornadalaboral.loc:8080/'
  : 'https://api.controldejornadalaboral.es/';

/**
 *
 * FORCE PRODUCTION ENVIRONMENT
 *
 */

// export const UPLOADS_URL = 'https://app.controldejornadalaboral.es';
//
// export const API_URL = 'https://api.controldejornadalaboral.es/';

export const EPS = {
    USER_BLOQUEAR: 'user/bloquear',
    USER_CHANGE_PASSWORD: 'user/change-password',
    USER_CHECK_ACCESS_TOKEN: 'user/check-access-token',
    USER_COMPROBAR_EMAIL: 'user/comprobar-email',
    USER_DETENER: 'user/detener',
    USER_DESBLOQUEAR: 'user/desbloquear',
    USER_FECHA_INICIO_FIN: 'user/get-fecha-inicio-fin-v2',
    USER_INICIAR: 'user/iniciar',
    USER_LOGIN: 'user/login',
    USER_REGISTER: 'user/register',
    USER_TRABAJADORES_A_CARGO: 'user/trabajadores-a-cargo',
    USER_TRABAJANDO: 'user/trabajando-v2',
    USER_PAUSAR: 'user/iniciar-pausa',
    USER_REANUDAR: 'user/detener-pausa',
    USER_VIEW: 'user/view',
    USER_FIND_BY_PIN: 'user/find-by-pin',
    USER_VIEW_HEADERS: 'user/view-headers',

    USUARIO: 'usuario',
    USUARIOS: 'usuarios',

    EMPRESA_PUEDE_CREAR_MAS_EMPLEADOS: 'empresa/puede-crear-mas-empleados',
    EMPRESA_LIMITE_EMPLEADOS_ALCANZADO: 'empresa/limite-empleados-alcanzado',
    EMPRESA_NOTIFICAR_ERROR_IMAGEN_PIN: 'empresa/notificar-error-imagen-pin',

    TIEMPO: 'tiempo',
    TIEMPOS: 'tiempos',
    TIEMPO_NEXT: 'tiempo/next',
    TIEMPO_IMAGE: 'tiempo/image',

    EMPRESA_MENSAJE: 'empresa/mensaje',

    LIBRES_VACACIONES: 'libres-vacaciones',

    CATEGORIAS: 'fichero-categoria',
    FICHEROS: 'fichero/get-ficheros-por-categoria',
    FICHERO: 'fichero/get-fichero'



}
