import React, {useState, useEffect} from 'react';
import CenteredLayout from "../../layouts/CenteredLayout";
import {API_URL, EPS} from "common/src/constants/Constants";
import {authHeader} from "../../helpers/auth-header";
import axios from "axios";
import {authenticationService} from "../../services/authentication.service";
import {ActivityIndicator, FlatList, Text, View} from "react-native";
import {Link} from "react-router-dom";

export default function Pausas(props) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {

    const bt = { headers: {...authHeader()} };

    if (!bt.headers.Authorization) {
      setLoading(false);
      return;
    }

    const config = {
      headers: {Authorization: bt.headers.Authorization},
    };

    const ep = API_URL + EPS.USER_TRABAJANDO + '/?id=' + authenticationService.currentUserValue.id;

    axios
      .get(ep, config)
      .then(res => {
        setData(res.data)
        setLoading(false)
      })
      .catch(e => {
        setLoading(false)
        console.log(e);
      });
  }

  if (loading || !data)
    return <ActivityIndicator />


  return (
    <CenteredLayout title={"Listado de pausas"}>
      {data.pausas.length === 0 ?
        <Text>No hay pausas para mostrar.</Text> :
        <FlatList
          data={data.pausas}
          keyExtractor={item => item.id.toString()}
          renderItem={({item}) => (
            <View>
              <p>{item.inicio} - {item.fin}</p>
            </View>
          )}
        />
      }

      <div className="row">
        <div className="col tl-wrapper">
          <Link className="btn btn-primary" to={'/'}>Volver</Link>
        </div>
      </div>
    </CenteredLayout>
  );
}
