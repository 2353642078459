import React from "react";


export const CustomTextarea = ({
  label,
  onChange,
  value,
  placeholder,
}) => {
  return (
    <div>
      {label && <p style={{ marginBottom: 10 }}>{label}</p>}
      <textarea
        onChange={e => onChange(e.target.value)}
        value={value}
        placeholder={placeholder}
        style={{
          height: 120,
          borderColor: "grey",
          borderWidth: 1,
          padding: 5,
          width: 300
        }}
      />
    </div>
  );
};
