import React, {useState} from "react";
import {Button, SafeAreaView, Text, TextInput, View} from "react-native";
import Colors from "../../constants/Colors";

export default function descripcion(props) {
  const [texto, setTexto] = useState(props.descripcion);

  return (
    <SafeAreaView style={{ flexGrow: 1, justifyContent: "space-around" }}>
      <View style={{ padding: 20, alignItems:'center' }}>
        <Text style={{ marginBottom: 10 }}>Agrega la descripción:</Text>
        <TextInput
          onChangeText={value => setTexto(value)}
          value={texto}
          style={{
            height: 120,
            borderColor: "grey",
            borderWidth: 1,
            padding: 4,
            width: 300
          }}
          multiline={true}
          ref={props.focus}
        />
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: 10, width: 300
          }}
        >
          <View style={{ width: "49%", borderWidth:1, borderColor: Colors.btPrimary }}>
            <Button
              title="Aceptar"
              onPress={() => props.cerrarDescripcion(texto)}
              color={Colors.btPrimary}
            />
          </View>
          <View style={{ width: "49%", borderColor: Colors.btCancel,borderWidth:1 }}>
            <Button
              title="Cancelar"
              color={Colors.btCancel}
              onPress={() => props.cancelDescripcion()}
            />
          </View>
        </View>
      </View>
    </SafeAreaView>
  );
}
