import DateTimePicker from "common/src/components/DateTimePicker";
import React from "react";

export default function selectDateTime(props) {
  return (
    <>
      <DateTimePicker
        date={props.date}
        display="spinner"
        onChange={props.setDate}
      />
      <div className="row">
        <button
          style={{ width: "100%", marginRight: "2px" }}
          className="btn-primary"
          onClick={props.cerrarSelectDateTime}
        >
          Aceptar
        </button>

        <button
          style={{ width: "100%", marginLeft: "2px" }}
          className="btn-cancel"
          onClick={props.cerrarSelectDateTime}
        >
          Cancelar
        </button>
      </div>
    </>
  );
}
