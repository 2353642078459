import {Platform} from 'react-native';

export default {
    wrapper: {
        ...Platform.select({
            web: {
                marginTop:20
            }
        })
    }
}
