import React, {Component} from 'react';
import moment from 'moment';
import {Text, View} from 'react-native';
import PropTypes from 'prop-types';

export default class Timer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            elapsedTime: null,
        };
    }

    startTimer = () => {
        this.timer();
        this.timerInterval = setInterval(this.timer, 1000);
    };

    componentDidMount() {
        this.startTimer();
    }

    componentWillUnmount() {
        this.stopTimer();
    }

    stopTimer = () => {
        clearInterval(this.timerInterval);
    };

    timer = () => {
        function pad(s) {
            return s < 10 ? '0' + s : s;
        }
        const ahora = moment();
        const desde = moment(this.props.date, 'DD-MM-YYYY HH:mm:ss');


        let ms = ahora.diff(desde);
        const fechaFutura = ms < 0;
        if (fechaFutura) {
            ms = desde.diff(ahora);
        }

        // inicio en el pasado
        const d = moment.duration(ms);
        const hours = Math.floor(d.asHours());
        const elapsedTime =
            (fechaFutura ? '-' : '') +
            (hours > 0 ? hours + ':' : '') +
            pad(d.minutes()) +
            ':' +
            pad(d.seconds());
        this.setState({elapsedTime});
    };

    render() {
        const {elapsedTime} = this.state;

        return (
            <View>
                <Text style={styles.text}>Trabajando desde hace {elapsedTime}</Text>
            </View>
        );
    }
}

const styles = {
    text: {
        fontSize: 16,
        marginTop: 10,
        textAlign: 'center',
    },
};

Timer.propTypes = {
    date: PropTypes.string.isRequired,
};
