import SignatureCanvas from 'common/src/components/SignatureCanvas';

import React, {Component} from 'react';
import {
    Button,
    Text,
    View,
} from 'react-native';

export default function firma(props) {

	return (
		<View style={props.styles.subContainer}>
            <Text style={{marginBottom: 40, textAlign: 'center', color: '#000000'}}>
                Introduzca su firma en el recuadro inferior
            </Text>

            <View style={{height: 275, marginBottom: 20}}>
                <SignatureCanvas
                    styles={props.styles}
                    working={props.working}
                    iniciarJornada={props.iniciarJornada}
                    detenerJornada={props.detenerJornada}
                    ocultarFirma={props.ocultarFirma}
                    webStyle={props.webStyle}
                    firmar={props.firmar}
                />
            </View>

            <Button
                color='#333'
                title={
                    props.working
                        ? 'Volver sin detener Jornada'
                        : 'Volver sin iniciar Jornada'
                }
                onPress={
                    props.ocultarFirma
                }
            />
        </View>
    );
}
