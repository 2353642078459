import React from 'react';
import style from './pinpage.module.css';

const PinButton = ({ number, onNumberSelected }) => {

    const onPress = (e) => {
        e.preventDefault()
        e.stopPropagation()
        onNumberSelected()
    }
    return <button
        className={style.item}
        onTouchStart={onPress}
        // onClick={onPress}
    >
        <span>{number}</span>
    </button>;
}

export default PinButton;
