import React from 'react';
import { PausaItem } from './PausaItem';

/**
 * Componente para que pinta un listado de pausas (un View, sin scroll)
 * @param pausas
 * @constructor
 */
export const PausasList = ({ pausas }) => {
  if (pausas.length === 0) return null;

  return (
    <div>
      {pausas.map(item => (
        <PausaItem item={item} key={item.id} />
      ))}
    </div>
  );
};
