import React from 'react';
/**
 * Componente que muestra una pausa (para listados)
 * @constructor
 */
export const PausaItem = ({ item }) => {
  return (
    <div
      style={style.wrapper}>
      <p style={style.title}>
        {item.inicio} - {item.fin}
      </p>

      {item.motivo_nombre.length > 0 && (
        <p style={style.motivo}>Motivo: {item.motivo_nombre}</p>
      )}
      {item.observaciones.length > 0 && (
        <p style={style.observaciones}>Observaciones: {item.observaciones}</p>
      )}
    </div>
  );
};


let style = {
  wrapper: {
    marginBottom: 20,
    paddingBottom: 10,
    borderBottom: '1px solid #ccc',
  },
  title: {
    fontWeight: '600',
    marginTop: 0,
    marginBottom: 10
  },
  motivo: {
    marginTop: 0,
    marginBottom: 10
  },
  observaciones: {
    marginTop: 0,
    marginBottom: 10
  }
};
