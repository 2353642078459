import React, {useEffect, useState} from 'react';
import {API_URL, EPS} from "common/src/constants/Constants";
import {Image} from "react-native";
import axios from 'axios';
import {authHeader} from "../../helpers/auth-header";
import CenteredLayout from "../../layouts/CenteredLayout";
import {Link} from "react-router-dom";
import {PausasList} from "common/src/components/Pausas/PausasList";


export default function TiemposDetalle(props) {

    const [item, setItem] = useState([]);
    const [loading, setLoading] = useState(true);
    const { id } = props.match.params;

    useEffect(() => {
        const endpoint = API_URL + EPS.TIEMPOS + '/'+id+'?expand=pausas';

        const btd = { headers: {...authHeader()} };

        if (!btd.headers.Authorization) {
            setLoading(false);
            return;
        }

        const config = {
            headers: {Authorization: btd.headers.Authorization},
        };

        axios
            .get(endpoint, config)
            .then(d => {
                setItem(d.data);
                setLoading(false);
            })
            .catch(e => console.log(e));
    }, []);

    return (
        <CenteredLayout title={"Detalle de tiempo"}>
            <div className="row tiempoDetalle">
                <div className="col">
                    <p><strong>Inicio:</strong> {item.inicio}</p>

                    {item.descripcion_inicio && item.descripcion_inicio.length > 0 &&
                        <>
                            <p className="title">Descripción: </p>
                            <p className="desc">{item.descripcion_inicio}</p>
                        </>
                    }

                    {item.firma_inicio && item.firma_inicio.length > 0 &&
                    <>
                        <p className="title">Firma: </p>
                        <Image
                            style={{
                                height: 100,
                            }}
                            source={{uri: item.firma_inicio}}
                        />
                    </>
                    }
                </div>

                <div className="col">
                    <p><strong>Fin:</strong> {item.fin}</p>

                    {item.descripcion_fin && item.descripcion_fin.length > 0 &&
                    <>
                    <p className="title">Descripción: </p>
                    <p className="desc">{item.descripcion_fin}</p>
                        </>
                    }

                    {item.firma_fin && item.firma_fin.length > 0 &&
                    <>
                        <p className="title">Firma: </p>
                        <Image
                            style={{
                                height: 100,
                            }}
                            source={{uri: item.firma_fin}}
                        />
                    </>
                    }
                </div>
            </div>
            <div className="row">
                <div className="col">
                    {item.pausas?.length > 0 && (
                      <div style={{marginTop: 40}}>
                          <h3 style={{marginBottom: 12}}>
                              Pausas
                          </h3>
                          <PausasList pausas={item.pausas}/>
                      </div>
                    )}
                </div>
            </div>
            <div className="row">
                <div className="col tl-wrapper">
                    <Link className="btn btn-primary" to={'/tiempos'}>Volver</Link>
                </div>
            </div>
        </CenteredLayout>
    );
}
