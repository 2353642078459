import { Text, View, TouchableOpacity } from "react-native";
import { Link } from "react-router-dom";
import React from "react";
import PropTypes from "prop-types";

export default function TiempoItem(props) {
  const item = props.item;

  return (
    <>
      <TouchableOpacity style={styles.row}>
        <Link style={styles.link} to={"tiempo/" + item.id}>
          <Text style={styles.col}>
            {item.inicio} - {item.fin}
          </Text>
        </Link>
      </TouchableOpacity>
    </>
  );
}

const styles = {
  row: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderBottomColor: "#e7e7e7",
    paddingLeft: 10,
    paddingRight: 10
  },
  col: {
    width: "50%",
    lineHeight: 44
  },
  link: {
    textDecorationLine: "none",
    width: "100%"
  }
};

TiempoItem.propTypes = {
  item: PropTypes.object.isRequired
};
