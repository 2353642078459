import React from 'react';

export const CustomSelect = ({
  items,
  onChange,
}) => {
  return (
    <div>
      <select style={{ width: '100%', height: 35 }} onChange={(e) => onChange(e.target.value)}>
        <option value="">Selecciona una opción...</option>
        {items.map(item => <option key={item.value} value={item.value}>{item.label}</option>)}
      </select>
    </div>
  );
};
