const tintColor = "#2f95dc";

export default {
  tintColor,
  tabIconDefault: "#ccc",
  tabIconSelected: tintColor,
  tabBar: "#fefefe",
  errorBackground: "red",
  errorText: "#fff",
  warningBackground: "#EAEB5E",
  warningText: "#666804",
  noticeBackground: tintColor,
  noticeText: "#fff",
  inputPlaceholder: "#4d4d4d",
  btPrimary: "#208BEC",
  btCancel: "#dc3645"
};
