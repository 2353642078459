import React, {useEffect, useState} from 'react';
import CenteredLayout from "../../layouts/CenteredLayout";
import {API_URL, EPS} from "common/src/constants/Constants";
import {authHeader} from "../../helpers/auth-header";
import axios from 'axios';
import {ActivityIndicator} from "react-native";
import FicheroListItem from "common/src/components/Fichero/FicheroListItem";

const Ficheros = props => {

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);
    const [isShowingConfirmationModal, setIsShowingConfirmationModal] = useState(false)

    const { id } = props.match.params;
    const { title } = props.match.params;

    function getData() {
        // console.log('get data')
        const endpoint = API_URL + EPS.FICHEROS;
        setLoading(true)

        const btd = { headers: {...authHeader()} };

        if (!btd.headers.Authorization) {
            setLoading(false);
            return;
        }

        const config = {
            headers: {Authorization: btd.headers.Authorization},
            params: {categoria_id: id}
        };

        axios
          .get(endpoint, config)
          .then(d => {
              setData(d.data);
              setLoading(false);
          })
          .catch(e => {console.log(e); setLoading(false)});
    }

    useEffect(() => {
        getData();
    }, [])


    const onFicheroChange = (item) => {
        setData(data.map(fichero => {
            return fichero.id === item.id ? item : fichero;
        }))
    }

    if (loading)
        return <ActivityIndicator />

    return <CenteredLayout title={"Ficheros de la categoría "+title}>
        <div className="row tiempoDetalle">
            <div className="col">
                {data.length > 0 ? (
                  data.map((f, index) => (
                    <FicheroListItem
                      key={index}
                      item={f}
                      isDownloading={isDownloading}
                      setIsDownloading={setIsDownloading}
                      setIsShowingConfirmationModal={setIsShowingConfirmationModal}
                      isShowingConfirmationModal={isShowingConfirmationModal}
                      onChange={onFicheroChange}
                    />
                  ))
                ) : (
                  <p>No hay ficheros para mostrar</p>
                )}
            </div>

        </div>
    </CenteredLayout>
}

export default Ficheros;
