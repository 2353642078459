import React, {useEffect, useState, useContext} from "react";
import moment from "moment";
import {Button, TouchableOpacity, View} from "react-native";
import {API_URL, EPS} from "../../constants/Constants";
import {analyticsTrackEvent} from "web/src/hooks/withGaTracker";
import Icon from "react-native-vector-icons/dist/SimpleLineIcons";
import Modal from "../Modal";
import axios from "axios";
import styles from "./styles";
import {CustomSelect, CustomTextarea} from "../Forms";
import Colors from "../../constants/Colors";
import util from './util';
import Alert from "../Alert";
import {CustomDateTimePicker} from "./CustomDateTimePicker";
import {AppContext} from "../../Context/AppContext";

export const IniciarDetenerPausa = ({
   isPaused,
   trabajadorId,
   jornadaChange,
   token,
   handleIsPaused,
   handleExitToPinPage
 }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [date, setDate] = useState(moment().toDate());
  const [
    iniciarDetenerPausarButtonDisabled,
    setIniciarDetenerPausarButtonDisabled,
  ] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pausaMotivoItems, setPausaMotivoItems] = useState([]);
  const [pausaMotivoId, setPausaMotivoId] = useState(null);
  const [observacionPausa, setObservacionPausa] = useState('');
  const [changeHourVisible, setChangeHourVisible] = useState(false);
  const [changeDateVisible, setChangeDateVisible] = useState(false);
  const [pinUser, setPinUser] = useState()

  const motivosPausa = JSON.parse(localStorage.getItem("motivosPausa"));
  const accessToken = token ? token : localStorage.access_token;

  const context = useContext(AppContext);

  useEffect(() => {
    if (context.currentUser?.rol === 4)
      setPinUser(true)
  }, [])

  const handleIniciarPausa = () => {
    // console.log('handleIniciarPausa');
    setModalVisible(true);
    setDate(moment().toDate());
    setIniciarDetenerPausarButtonDisabled(true);
  };

  useEffect(() => {
    if (motivosPausa) {
      const motivoPausaArray = motivosPausa.map(motivo => {
        return {
          label: motivo.nombre,
          value: motivo.id,
        };
      });

      // @ts-ignore
      setPausaMotivoItems(motivoPausaArray);
    }
  }, []);
  /**
   * Por el momento solamente modifica la descripción de fin
   * Text string
   */
  const handleChangeObservacionPausa = (text) => {
    setObservacionPausa(text);
  };

  const handleChangeMotivoPausa = (id) => {
    setPausaMotivoId(id);
    setIniciarDetenerPausarButtonDisabled(false);
  };

  const _handlePausarJornada = async () => {
    if (!pausaMotivoId) {
      Alert('Error', 'El motivo de la pausa es requerido');
      return;
    }

    // console.log('_handlePausarJornada');
    setLoading(true);

    const trabajadorIdParam = trabajadorId ? '?id=' + trabajadorId : '';

    const config = {
      headers: { Authorization: 'Bearer ' + accessToken },
    };

    axios
      .post(
        API_URL + EPS.USER_PAUSAR + trabajadorIdParam,
        {
          inicio: util.convertDate(date),
          motivo_id: pausaMotivoId,
          observaciones: observacionPausa,
        },
        config,
      )
      .then(data => {
        if (data.data.status) {
          setModalVisible(false);
          setPausaMotivoId(null);
          setObservacionPausa('');

          jornadaChange && jornadaChange();
          handleIsPaused(true);
          if (pinUser) handleExitToPinPage();
          analyticsTrackEvent('start_pausa');
        } else {
          if (data.data.data.message === 'Empresa no activa') {
            Alert(
              'Error',
              'La configuración actual de tu empresa no permite pausar / reanudar jornada. Ponte en contacto con la persona responsable',
            );
          } else {
            Alert('Error', 'Error al pausar jornada');
          }
        }

        setLoading(false);
      })
      .catch(error => {
        console.log('error _handlePausarJornada');
        console.log(error);
        Alert('Error', 'Error accediendo al servidor (1)');
        setLoading(false);
      });
  };

  const handleDetenerPausa = () => {
    // console.log('reanudar pausa');
    setModalVisible(true);
    setDate(moment().toDate());
  };

  const _handleReanudarJornada = async () => {
    const config = {
      headers: { Authorization: 'Bearer ' + accessToken },
    };


    const trabajadorIdParam =
      trabajadorId ? '?id=' + trabajadorId : '';

    setLoading(true);

    axios
      .post(
        API_URL + EPS.USER_REANUDAR + trabajadorIdParam,
        {
          fin: util.convertDate(date),
        },
        config,
      )
      .then(data => {
        if (data.data.status) {
          handleIsPaused(false);
          analyticsTrackEvent('stop_pausa');
        } else {
          if (data.data.data.message === 'Empresa no activa') {
            Alert(
              'Error',
              'La configuración actual de tu empresa no permite pausar / reanudar jornada. Ponte en contacto con la persona responsable',
            );
          } else {
            Alert('Error', 'Error al reanudar la jornada');
          }
        }

        jornadaChange && jornadaChange();
        setLoading(false);
        setModalVisible(false);
        if (pinUser) handleExitToPinPage();
      })
      .catch(error => {
        console.log('error handleDetenerJornada');
        console.log(error);
        Alert('Error', 'Error accediendo al servidor (2)');
        setLoading(false);
      });
  };

  const buttonBackgroundByPauseState = () => {
    return isPaused ? '#77C661' : '#F2CA30';
  };

  const handleChangeHour = () => {
    setChangeHourVisible(true);
  };

  const handleChangeDate = () => {
    setChangeDateVisible(true);
  };

  const handleCerrarDateTime = () => {
    setChangeHourVisible(false);
    setChangeDateVisible(false)
  };

  const changeDate = (d) => {

    if (
      moment(d).isValid()
    ) {
      const newDate = moment(date);

      const selectedDate = moment(d);
      newDate.set("year", selectedDate.year());
      newDate.set("month", selectedDate.month());
      newDate.set("date", selectedDate.date());

      setChangeDateVisible(false)
      setDate(newDate.toDate())

    } else {
      console.error("Fecha no válida");
    }
  };

  // if (loading) {
  //   return <p>Espere...</p>;
  // }

  return (
    <div>
      {/* boton iniciar / detener pausa dependiendo si está pausada la jornada */}
      <div
        style={{
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: 20,
          display: "flex"
        }}>
        <div
          style={{
            backgroundColor: buttonBackgroundByPauseState(),
            borderRadius: 50,
            display: "flex"
          }}>
          <Icon
            color="#fff"
            name={isPaused ? 'control-play' : 'control-pause'}
            size={25}
            style={{ padding: 20, display: 'flex' }}
            onPress={isPaused ? handleDetenerPausa : handleIniciarPausa}
          />
        </div>
      </div>

      <Modal
        visible={modalVisible}
        animationType="slideUp"
        animationInTiming={5000}
        onRequestClose={() => {
          setModalVisible(false);
          setChangeDateVisible(false);
          setChangeHourVisible(false);

        }}>
        <div style={{ flex: 1, paddingBottom: 50 }}>
          <div style={styles.subContainer}>
            <div>
              {date && (
                <>
                  <p
                    style={{
                      textAlign: 'center',
                      color: '#000',
                      marginBottom: 10,
                    }}>
                    Vas a {isPaused ? 'reanudar' : 'pausar'} jornada el:
                  </p>
                  <p
                    style={{
                      textAlign: 'center',
                      color: '#000',
                      marginBottom: 10,
                    }}
                    onClick={handleChangeHour}>
                    {moment(date).format('DD-MM-YYYY HH:mm:ss')}
                  </p>
                </>
              )}
            </div>
            {changeHourVisible || changeDateVisible ? (
              <CustomDateTimePicker
               isTypeCalendar={changeDateVisible}
               changeDate={changeDate}
               handleClose={handleCerrarDateTime}
              />
            ) : (
              <>
                {!isPaused && (
                  <div style={{ marginTop: 30 }}>
                    <div style={{ zIndex: 9, ...pausaStyles.row }}>
                      <CustomSelect
                        items={pausaMotivoItems}
                        onChange={handleChangeMotivoPausa}
                      />
                    </div>
                    <div style={pausaStyles.row}>
                      <CustomTextarea
                        placeholder="Agrega una descripción..."
                        onChange={handleChangeObservacionPausa}
                        value={observacionPausa}
                      />
                    </div>
                  </div>
                )}

                <div
                  style={{
                    ...pausaStyles.row,
                    ...pausaStyles.iniciarButtonWrapper
                  }}>
                    <div style={{ marginBottom: 20, marginTop: 30 }}>
                      <Button
                        title={isPaused ? 'Reanudar' : 'Pausar'}
                        onPress={() => {
                          isPaused
                            ? _handleReanudarJornada()
                            : _handlePausarJornada();
                        }}
                        disabled={iniciarDetenerPausarButtonDisabled}
                      />
                    </div>

                  <Button
                    title="Cancelar"
                    color={Colors.btCancel}
                    onPress={() => {
                      setModalVisible(false);
                      setPausaMotivoId(null);
                      setObservacionPausa('');
                    }}
                  />
                </div>
              </>
            )}
          </div>
          {!changeHourVisible && !changeDateVisible && (
            <View>
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  paddingHorizontal: 20
                }}>
                <TouchableOpacity
                  onPress={handleChangeHour}
                  style={styles.iconButtons}>
                  <View style={{ alignItems: 'center' }}>
                    <Icon name="clock" color="#313638" size={20} />
                  </View>
                </TouchableOpacity>
                <TouchableOpacity
                  onPress={handleChangeDate}
                  style={styles.iconButtons}>
                  <View style={{ alignItems: 'center' }}>
                    <Icon name="calendar" color="#313638" size={20} />
                  </View>
                </TouchableOpacity>
              </View>
            </View>
          )}
        </div>
      </Modal>
    </div>
  );
};

const pausaStyles = {
  row: {
    marginBottom: 10,
  },
  iniciarButtonWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
};
