import React, {useState, useEffect} from 'react';
import LoggedIn from "./LoggedIn";
import Guest from "./Guest";
import PinLayout from "./PinLayout";
import {AppContext} from "common/src/Context/AppContext";
const Main = props => {
    const [lastOnIniciarDetenerDoneTime, setLastOnIniciarDetenerDoneTime] = useState('')
    const [lastUserData, setLastUserData] = useState(null);
    const [isPinLoading, setIsPinLoading] = useState(false);
    const [pinWebcamVisible, setPinWebcamVisible] = useState(false);
    const [lastIniciarDetenerJornadaStatus, setLastIniciarDetenerJornadaStatus] = useState(null);

    return <AppContext.Provider value={{
        onIniciarDetenerDone: (d) => {
            // console.log(d)
            // console.log('onIniciarDetenerDone no utilizado en web ', d)

            setLastIniciarDetenerJornadaStatus({ ...d, time: Date.now() })
            setLastOnIniciarDetenerDoneTime(Date.now());

            // console.log({ ...d, time: Date.now() })

        },
        lastOnIniciarDetenerDoneTime,
        lastUserData,
        setLastUserData,
        isPinLoading,
        setIsPinLoading,
        currentUser: props.currentUser,
        pinWebcamVisible,
        setPinWebcamVisible,
        lastIniciarDetenerJornadaStatus
    }}>

        {props.currentUser !== null && props.currentUser.rol === 0 && <LoggedIn user={props.currentUser}>{props.children}</LoggedIn>}
        {props.currentUser !== null && props.currentUser.rol === 4 && <PinLayout>{props.children}</PinLayout>}
        {props.currentUser === null && <Guest>{props.children}</Guest>}

    </AppContext.Provider>
}

// Main.whyDidYouRender = true


export default Main;
