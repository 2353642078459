import React from 'react';
import {Button, View} from 'react-native';
import styles from './style';
import PropTypes from 'prop-types';

/**
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const ButtonSimple = props => {

    let wrapperStyle = {};
    let childProps = {...props};
    if  (props.wrapperStyle !== undefined) {
         wrapperStyle = props.wrapperStyle;
        delete childProps.wrapperStyle;
    }

    return (
        <View style={{...styles.wrapper, ...wrapperStyle}}>
            <Button {...childProps} />
        </View>
    )
}

ButtonSimple.propTypes = {
    wrapperStyle: PropTypes.array,
    ...Button.prop

}



export default ButtonSimple;
