// import {Platform, StyleSheet} from 'react-native';
//
// const styles = StyleSheet.create({
//     container: {
//         backgroundColor: 'yellow',
//
//         ...Platform.select({
//             web: {
//                 backgroundColor: 'grey',
//             }
//         }),
//     },
// });
//
// export default styles;

import { Platform } from "react-native";

const styles = {
  subContainer: {
    flex: 1,
    justifyContent: "center",
    // alignItems: 'stretch',
    padding: 20
  },
  wrapperContainer: {
    marginTop: 10
  },
  buttonCalendarWrapper: {
    ...Platform.select({
      ios: {
        position: "absolute",
        marginLeft: 20,
        padding: 20,
        width: "100%",
        bottom: 20,
        alignSelf: "stretch"
      },
      android: {
        position: "absolute",
        marginLeft: 20,
        padding: 20,
        width: "100%",
        bottom: 20,
        alignSelf: "stretch"
      }
    })
  },
  buttonStyles: {
    marginBottom: 20
  },
  iniciarButtonWrapper: {
    marginBottom: Platform.OS === "ios" ? 0 : 20
  },
  iconButtons: {
    width: 50,
    height: 50,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center"
  }
};

export default styles;
