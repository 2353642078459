import React from 'react';

import './styles.scss';
const Modal = props => {

    const bodyClassName = "g-modal-visible";

    const onClose = () => {
        props.onRequestClose && props.onRequestClose();
    }

    if (props.visible)
        document.body.classList.add(bodyClassName);
    else
        document.body.classList.remove(bodyClassName);

    return (props.visible && <div className="g-modal">

        <div className="g-modal-content" onClick={e => e.stopPropagation()} tabIndex="0" onKeyDown={e => e.keyCode === 27 && onClose()}>
            {props.children}
            <span className="g-modal-close" onClick={onClose}>X</span>
        </div>
    </div>);
}

export default Modal;
