import React, { Component } from "react";
import axios from "axios";
import {
  ActivityIndicator,
  Button,
  Platform,
  Text,
  TouchableOpacity,
  View,
  KeyboardAvoidingView
} from "react-native";
import Modal from "../Modal";
import moment from "moment";
import Firma from "common/src/components/Firma";
import Timer from "common/src/components/Timer";
import { API_URL, EPS } from "../../constants/Constants";
import Alert from "common/src/components/Alert";
import SelectDateTime from "common/src/components/SelectDateTime";
import Descripcion from "common/src/components/Descripcion";
import styles from "./styles";
import ButtonSimple from "../Interface/ButtonSimple";
import ReactGA from "react-ga";
import { AppContext } from "../../Context/AppContext";
import { getTimeZone } from "../../util/Date";
import Icon from "react-native-vector-icons/dist/SimpleLineIcons";
import Colors from "../../constants/Colors";
import {analyticsTrackEvent} from "web/src/hooks/withGaTracker";
import {IniciarDetenerPausa} from "./IniciarDetenerPausa";
import {Link} from "react-router-dom";

ReactGA.initialize("UA-145457735-3");

class IniciarDetenerJornadaComponent extends Component {
  static contextType = AppContext;
  minDate = moment("2000-01-01");
  accessToken = this.props.token ? this.props.token : localStorage.access_token;

  constructor(props) {
    super(props);
    this.state = {
      changeHourVisible: false,
      date: null,
      descripcion: "",
      descripcionVisible: false,
      error: "",
      firma: null,
      firmaVisible: false,
      loading: this.props.loading,
      modalVisible: false,
      mostrarFirma: false,
      seleccionHoraVisible: false,
      screenHeight: null,
      screenWith: null,
      cambiarFechaWebVisible: false,
      pinUser: false,
      validNewDate: false,
      motivosPausa: JSON.parse(localStorage.getItem("motivosPausa")),
      isPaused: this.props.isPaused
    };
    this.dateWebRef = React.createRef();
  }

  componentDidMount() {
  //   // Si es usuario pin, lanzamos lo redirecionamos directamtente a iniciar/detener jornada
    if (this.context?.currentUser?.rol === 4) {
      this.setState({ pinUser: true });
  //     this.state.working
  //       ? this.handleDetenerJornada()
  //       : this.handleIniciarJornada();
    }
  }

  static getDerivedStateFromProps(props, state) {
    const res = { ...props, ...state };
    return res;
  }

  startTimer = () => {
    this.comprobarEstadoTrabajandoTimer = setInterval(
      this.comprobarEstadoTrabajando,
      30000
    );
  };

  clearTimer = () => {
    clearInterval(this.comprobarEstadoTrabajandoTimer);
  };

  handleTieneQueFirmar = () => {
    const fin = moment(this.state.date);
    const inicio = moment(this.state.workingStartTime, "DD-MM-YYYY hh:mm:ss");

    if (this.state.working && fin.diff(inicio) <= 0) {
      Alert(
        "Error",
        "No puedes finalizar antes de la hora que empezaste a trabajar (" +
          this.state.workingStartTime +
          ")."
      );
      this.setState({ loading: false });
    } else {
      //TODO - Revisar cuando se pase a la siguiente fase con log
      const empresaData = JSON.parse(localStorage.getItem("empresaData"));
      const userData = JSON.parse(localStorage.getItem("userData"));

      const firma = empresaData?.firma ?? 2;
      const debeFirmar = userData?.debeFirmar ?? true;

      // console.log("Firma configurada a " + firma);
      // const FIRMA_NULA = 0; //ningún usuario firma
      // const FIRMA_INTERMEDIA = 1; //trabajadores asignados a encargados
      // const FIRMA_TODOS = 2; // todos firman

      if (debeFirmar) {
        this.setState({
          modalVisible: true,
          seleccionHoraVisible: false,
          firmaVisible: true
        });
      } else {
        this.setState({
          modalVisible: false,
          seleccionHoraVisible: false,
          firmaVisible: false
        });
        if (this.state.working) {
          this._handleDetenerJornada();
        } else {
          this._handleIniciarJornada();
        }
      }
    }
  };

  handleFirmar = (img, field) => {
    // console.log(img);
    this.setState(
      {
        modalVisible: false,
        firmaVisible: false,
        [field]: img
      },
      () => {
        if (this.state.working) {
          this._handleDetenerJornada();
        } else {
          this._handleIniciarJornada();
        }
      }
    );
  };

  handleIniciarJornada = () => {
    // console.log("handleIniciarJornada");

    const config = {
      headers: { Authorization: "Bearer " + this.accessToken }
    };

    let timezone = "?" + "timezone=" + getTimeZone();

    axios
      .get(
        API_URL + EPS.USER_FECHA_INICIO_FIN + timezone,
        config
      )
      .then(response => {
        // console.log(response);

        if (!response.data.data) {
          // console.log(response.data);
          this.context.onIniciarDetenerDone({
            action: "iniciar",
            result: false,
            error: response.data.error_code
          });
          switch (response.data.error_code) {
            case "maximos_inicios_sesion_grupo_horario_excedido":
              Alert(
                "Error",
                "Ya has llegado al máximo de jornadas que tenías que iniciar hoy!"
              );
              break;
            case "festivo":
              Alert(
                "Error",
                "Tu empresa ha marcado el día de hoy como festivo. Disfrútalo ;)"
              );
              break;
            case "vacaciones_libre":
              Alert(
                "Error",
                "Tu empresa ha marcado este día como Vacaciones / Libre."
              );
              break;
            default:
              Alert("Error", "No es posible iniciar jornada.");
          }
        } else
          this.setState({
            modalVisible: true,
            seleccionHoraVisible: true,
            firmaVisible: false,
            date: moment(response.data.data).toDate()
          });
      })
      .catch(error => {
        // console.log("error get jornada");
        // console.log(error);
        this.setState({ loading: false });
      });
  };

  convertDate(date) {
    function pad(s) {
      return s < 10 ? "0" + s : s;
    }

    return (
      [pad(date.getDate()), pad(date.getMonth() + 1), date.getFullYear()].join(
        "-"
      ) +
      " " +
      [
        pad(date.getHours()),
        pad(date.getMinutes()),
        pad(date.getSeconds())
      ].join(":")
    );
  }

  _handleIniciarJornada = async () => {
    // console.log("_handleIniciarJornada");
    this.setState({ loading: true });



    const config = {
      headers: { Authorization: "Bearer " + this.accessToken }
    };

    axios
      .post(
        API_URL + EPS.USER_INICIAR,
        {
          firma_inicio: this.state.firma,
          inicio: this.convertDate(this.state.date),
          latitud: "",
          longitud: "",
          descripcion_inicio: this.state.descripcion,
          client_timezone: getTimeZone()
        },
        config
      )
      .then(data => {
        if (data.data.status) {
          this.context.onIniciarDetenerDone({
            action: "iniciar",
            result: true
          });
          this.setState({
            working: data.data.status,
            workingStartTime: this.convertDate(this.state.date)
          });
          this.props.jornadaChange && this.props.jornadaChange();
          analyticsTrackEvent('start_working');
        } else {
          // console.log(data);
          if (data.data.data.message === "Empresa no activa")
            Alert(
              "Error",
              "La configuración actual de tu empresa no permite iniciar / finalizar jornada. Ponte en contacto con la persona responsable"
            );
          else Alert("Error", "Error al iniciar jornada");
        }

        this.handleChangeDescripcion("");
        this.setState({ loading: false });
      })
      .catch(error => {
        // console.log("error handleIniciarJornada");
        // console.log(error);
        Alert("Error", "Error accediendo al servidor (1)");
        this.setState({ loading: false });
      });
  };

  handleDetenerJornada = () => {

    const config = {
      headers: { Authorization: "Bearer " + this.accessToken }
    };

    let timezone = "?" + "timezone=" + getTimeZone();

    axios
      .get(
        API_URL + EPS.USER_FECHA_INICIO_FIN + timezone,
        config
      )
      .then(response => {
        this.setState({
          modalVisible: true,
          seleccionHoraVisible: true,
          firmaVisible: false,
          date: moment(response.data.data).toDate()
        });
        // console.log(response.data.data);
      })
      .catch(error => {
        // console.log("error get jornada");
        // console.log(error);
        this.setState({ loading: false });
      });
  };

  _handleDetenerJornada = async () => {

    const config = {
      headers: { Authorization: "Bearer " + this.accessToken }
    };

    this.setState({ loading: true });

    axios
      .post(
        API_URL + EPS.USER_DETENER,
        {
          firma_fin: this.state.firma_fin ?? "",
          fin: this.convertDate(this.state.date),
          latitud_fin: "",
          longitud_fin: "",
          descripcion_fin: this.state.descripcion,
          client_timezone: getTimeZone()
        },
        config
      )
      .then(data => {
        // console.log(data.data);
        if (data.data.status) {
          this.context.onIniciarDetenerDone({
            action: "detener",
            result: true
          });
          this.setState({ working: false });
        } else {
          if (data.data.data.message === "Empresa no activa")
            Alert(
              "Error",
              "La configuración actual de tu empresa no permite iniciar / finalizar jornada. Ponte en contacto con la persona responsable"
            );
          else Alert("Error", "Error al detener la jornada");
        }

        this.handleChangeDescripcion("");
        this.props.jornadaChange && this.props.jornadaChange();
        this.setState({ loading: false });
        analyticsTrackEvent('stop_working');
      })
      .catch(error => {
        // console.log("error handleDetenerJornada");
        // console.log(error);
        Alert("Error", "Error accediendo al servidor (2)");
        this.setState({ loading: false });
      })
  };

  /**
   * Por el momento solamente modifica la descripción de fin
   * Text string
   */
  handleChangeDescripcion = text => {
    this.setState({ descripcion: text });
  };

  setDate = (event, date) => {
    if (typeof date !== "undefined") {
      this.setState({ date});
    }

    this.setState({ changeHourVisible: true });
  };

  handleChangeHour = () => {
    this.setState({
      cambiarFechaWebVisible: false,
      changeHourVisible: true,
      descripcionVisible: false
    });
  };

  handleCerrarDateTime = () => {
    this.setState({
      changeHourVisible: false
    });
  };

  handleCerrarFirma = () => {
    // if (this.state.pinUser) this.props.onCancelPinButton();

    this.setState({
      modalVisible: false,
      firmaVisible: false
    });
  };

  handleCerrarIniciarDetener = () => {
    this.setState({
      modalVisible: false,
      seleccionHoraVisible: false
    });
  };

  handleAgregarDescripcion = () => {
    this.setState(
      {
        changeHourVisible: false,
        descripcionVisible: true,
        seleccionHoraVisible: false
      },
      () => {
        if (
          this._textInputObservacionesRef !== null &&
          typeof this._textInputObservacionesRef !== "undefined"
        ) {
          this._textInputObservacionesRef.focus();
        }
      }
    );
  };

  handleChangeDate = () => {
    if (
      this.dateWebRef.current.value &&
      moment(this.dateWebRef.current.value).isValid()
    ) {
      const newDate = moment(this.state.date);

      const selectedDate = moment(this.dateWebRef.current.value);
      newDate.set("year", selectedDate.year());
      newDate.set("month", selectedDate.month());
      newDate.set("date", selectedDate.date());

      this.setState({
        cambiarFechaWebVisible: false,
        date: newDate.toDate()
      });
    } else {
      console.error("Fecha no válida");
    }
  };

  handleCancelChangeDate = () => {
    console.log(
      "Class: IniciarDetenerJornadaComponent, Function: handleCancelChangeDate, Line 557 (): "
    );
    this.setState({ ...this.state, cambiarFechaWebVisible: false });
  };

  handleCerrarDescipcion = texto => {
    this.setState({
      descripcionVisible: false,
      seleccionHoraVisible: true,
      descripcion: texto
    });
  };

  handleCancelDescripcion = () => {
    this.setState({ descripcionVisible: false, seleccionHoraVisible: true });
  };

  handleIsPaused = (status) => {
    this.setState({ isPaused: status })
  }

  render() {
    const { working, loading, motivosPausa, isPaused } = this.state;

    if (loading)
      return <ActivityIndicator />

      return (
      <View>

        <View styles={styles.wrapperContainer}>
          {working ? <>
              {!isPaused && <Timer date={this.state.workingStartTime}/>}

              <ButtonSimple
                title={"Detener jornada"}
                onPress={this.handleDetenerJornada}
              />

              {motivosPausa.length > 0 && (
                <View>
                  {isPaused && (
                    <Text
                      style={{
                        fontSize: 40,
                        fontWeight: '700',
                        color: this.state.pinUser ? '#FFFFFF' : '#6C6C6C',
                        marginTop: 30,
                        textAlign: 'center',
                      }}>
                      Jornada pausada
                    </Text>
                  )}


                  <div style={{ marginTop: 20, marginBottom: 20}}>
                    <IniciarDetenerPausa
                      isPaused={isPaused}
                      trabajadorId={this.props.trabajadorId}
                      jornadaChange={this.props.jornadaChange}
                      token={this.props.token}
                      handleIsPaused={this.handleIsPaused}
                      handleExitToPinPage={this.props.onCancelPinButton}
                    />
                  </div>

                  { !this.state.pinUser &&<div style={{marginTop: 20, textAlign: "center"}}>
                     <Link style={{
                      color: this.state.pinUser ? '#FFFFFF' : 'rgb(33, 150, 243)',
                      textDecoration: 'none'
                    }} to={"pausas"}>
                      Ver pausas
                    </Link>
                  </div> }
                </View>
              )}
            </> :
            <Button
              title={"Iniciar Jornada"}
              onPress={this.handleIniciarJornada}
            />}
        </View>

        <Modal
          visible={this.state.modalVisible}
          animationType="slideUp"
          animationInTiming={5000}
          onRequestClose={() => {
            // console.log("on request close padre");
            // if (this.state.pinUser) this.props.onCancelPinButton();

            this.setState({
              modalVisible: false,
              changeHourVisible: false,
              descripcionVisible: false,
              firmaVisible: false,
              mostrarFirma: false,
              seleccionHoraVisible: false,
              cambiarFechaWebVisible: false
            });
          }}
        >
          <View style={{ flex: 1, paddingBottom: 50 }}>
            {this.state.seleccionHoraVisible && (
              // seleccion de hora
              <View style={styles.subContainer}>
                {this.state.date && (
                  <>
                    <Text
                      style={{
                        textAlign: "center",
                        color: "#000",
                        marginBottom: 10
                      }}
                    >
                      Vas a {working ? "detener" : "iniciar"} jornada el:
                    </Text>
                    <Text
                      style={{
                        textAlign: "center",
                        color: "#000",
                        marginBottom: 10
                      }}
                    >
                      { moment(this.state.date).format("DD-MM-YYYY HH:mm:ss") }
                    </Text>
                  </>
                )}
                {!this.state.changeHourVisible &&
                  !this.state.cambiarFechaWebVisible && (
                    <>
                      <View style={styles.iniciarButtonWrapper}>
                        <ButtonSimple
                          title={working ? "Detener" : "Iniciar"}
                          onPress={this.handleTieneQueFirmar}
                          style={styles.buttonStyles}
                        />
                      </View>
                      <View style={{ marginBottom: 5 }}>
                        <Button
                          title="Cancelar"
                          color={Colors.btCancel}
                          onPress={
                            this.handleCerrarIniciarDetener
                            // this.state.pinUser
                            //   ? this.props.onCancelPinButton
                            //   : this.handleCerrarIniciarDetener
                          }
                        />
                      </View>
                    </>
                  )}

                {this.state.descripcion.length > 0 && (
                  <View style={{ marginTop: 20, marginBottom: 20 }}>
                    <Text
                      style={{
                        textDecorationLine: "underline",
                        color: "grey",
                        marginBottom: 10
                      }}
                    >
                      Descripción:
                    </Text>
                    <TouchableOpacity onPress={this.handleAgregarDescripcion}>
                      <Text style={{ color: "grey" }}>
                        {this.state.descripcion}
                      </Text>
                    </TouchableOpacity>
                  </View>
                )}

                {this.state.cambiarFechaWebVisible && (
                  <div>
                    <input
                      type="date"
                      style={{
                        boxSizing: "border-box",
                        marginTop: 20,
                        width: "100%",
                        padding: 6
                      }}
                      ref={this.dateWebRef}
                      onChange={e => {
                        const { value } = e.target;

                        if ( moment(value).isAfter(this.minDate) )
                          this.setState({
                            validNewDate: true
                          });
                        else
                          this.setState({
                            validNewDate: false
                          });
                      }}
                    />
                    <div className="row">
                      <button
                        disabled={!this.state.validNewDate}
                        onClick={this.handleChangeDate}
                        className="btn-primary"
                        style={{ width: "100%", marginRight: "2px" }}
                      >
                        Aceptar
                      </button>
                      <button
                        onClick={this.handleCancelChangeDate}
                        className="btn-cancel"
                        style={{ width: "100%", marginLeft: "2px" }}
                      >
                        Cancelar
                      </button>
                    </div>
                  </div>
                )}

                <View style={styles.buttonCalendarWrapper}>
                  {this.state.changeHourVisible && (
                    <SelectDateTime
                      date={this.state.date}
                      display="spinner"
                      setDate={this.setDate}
                      cerrarSelectDateTime={this.handleCerrarDateTime}
                    />
                  )}

                  {!this.state.changeHourVisible &&
                    !this.state.descripcionVisible &&
                    !this.state.cambiarFechaWebVisible && (
                      <View style={rowDescripcionHora}>
                        <View
                          style={{
                            flexDirection: "row",
                            justifyContent: "space-between"
                          }}
                        >
                          <TouchableOpacity
                            onPress={this.handleChangeHour}
                            style={styles.iconButtons}
                          >
                            <View style={{ alignItems: "center" }}>
                              <Icon name="clock" color="#313638" size={20} />
                            </View>
                          </TouchableOpacity>

                          {Platform.OS !== "ios" && (
                            <TouchableOpacity
                              onPress={() => {
                                 this.setState(
                                    {
                                      cambiarFechaWebVisible: true,
                                      validNewDate: false
                                    },
                                    () => {
                                      setTimeout(() => {
                                        this.dateWebRef.current.click();
                                      }, 100);
                                    }
                                  );
                              }}
                              style={styles.iconButtons}
                            >
                              <View style={{ alignItems: "center" }}>
                                <Icon
                                  name="calendar"
                                  color="#313638"
                                  size={20}
                                />
                              </View>
                            </TouchableOpacity>
                          )}

                          <TouchableOpacity
                            onPress={this.handleAgregarDescripcion}
                            style={styles.iconButtons}
                          >
                            <View style={{ alignItems: "center" }}>
                              <Icon name="pencil" color="#313638" size={20} />
                            </View>
                          </TouchableOpacity>
                        </View>
                      </View>
                    )}
                </View>
              </View>
            )}

            {this.state.firmaVisible && (
              // Firma
              <Firma
                styles={styles}
                working={working}
                iniciarJornada={this._handleIniciarJornada}
                detenerJornada={this._handleDetenerJornada}
                ocultarFirma={this.handleCerrarFirma}
                webStyle={webStyle}
                firmar={this.handleFirmar}
              />
            )}

            {this.state.descripcionVisible && (
              // Descripción
              <KeyboardAvoidingView>
                <Descripcion
                  descripcion={this.state.descripcion}
                  changeDescripcion={this.handleChangeDescripcion}
                  cancelDescripcion={this.handleCancelDescripcion}
                  cerrarDescripcion={this.handleCerrarDescipcion}
                  focus={c => (this._textInputObservacionesRef = c)}
                />
              </KeyboardAvoidingView>
            )}
          </View>
        </Modal>
      </View>
    );
  }
}

const rowDescripcionHora = {
  ...Platform.select({
    web: {
      position: "absolute",
      bottom: -110,
      left: -60,
      right: -60
    }
  })
};

const webStyle = `.m-signature-pad--footer
.button {
    background: none;
    color: #157efb;
    font-size: 16px;
}
@media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .m-signature-pad {
    margin: 0%;
  }
}`;

const styleSignatureShadow = {
  shadowColor: "#000",
  shadowOffset: {
    width: 0,
    height: 1
  },
  shadowOpacity: 0.22,
  shadowRadius: 2.22,
  elevation: 3
};

export default IniciarDetenerJornadaComponent;
