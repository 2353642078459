import React, {useState, useEffect} from 'react';
import {NavLink} from "react-router-dom";
import Icon from "react-native-vector-icons/dist/SimpleLineIcons";
import {authenticationService} from "../services/authentication.service";
import {UPLOADS_URL} from "common/src/constants/Constants";

const LoggedIn = ({children, user}) => {

  const [menuVisible, setMenuVisible] = useState(true);
  const avatar = UPLOADS_URL + user.imagen_perfil;
  const empresaData = JSON.parse(localStorage.getItem('empresaData'));
  const userData = JSON.parse(localStorage.getItem('userData'));

  return <>
    <aside className={"sidebar-menu " + (menuVisible ? 'visible' : 'no-visible')}>
      <div className="logo">

        <a href="/">
          {__DEV__ ? (
            <div id="dev" style={{
              margin: '0 auto',
              width: '130px',
              height: '130px',
              background: 'rgba(255, 255, 255, .1',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
                            <span style={{
                              color: 'rgba(255, 2555, 255, .8)',
                              textAlign: 'center'
                            }}>EN<br/>DESARROLLO</span>
            </div>
          ) : (
            <div>{user.imagen_perfil?.length && <img src={avatar} alt="logo"/>}</div>
          )}
        </a>
      </div>
      <nav className="navbar navbar-expand navbar-dark bg-dark">
        <ul className="navbar-nav">
          <li><NavLink exact={true} activeClassName='is-active' to="/"><Icon name="home" color="#fff"
                                                                             size={14}/>
            <span>Home</span></NavLink></li>
          {empresaData.trabajador_tiempos_ver &&
            <li>
              <NavLink activeClassName='is-active' to="/tiempos" className="nav-item nav-link">
                <Icon name="clock" color="#fff" size={14}/> <span>Tiempos</span></NavLink>
            </li>}

          {empresaData.trabajador_vacaciones_libres_ver &&
            <li>
              <NavLink to="/vacaciones-libres" activeClassName="is-active" className="nav-item nav-link">
                <Icon name="calendar" color="#fff" size={14}/> <span>Vacaciones / Libres</span>
              </NavLink>
            </li>
          }


          {userData?.puedeVerFicheros &&
              <li>
                <NavLink to="/categorias" activeClassName="is-active" className="nav-item nav-link">
                  <Icon name="folder" color="#fff" size={14}/> <span>Ficheros</span>
                </NavLink>
              </li>
          }



            <li>
              <a href="#" onClick={() => authenticationService.logout()}>
                <Icon name="logout" color="#fff" size={14} />
                <span>Salir</span>
              </a>
          </li>
        </ul>
      </nav>

      <div className="toggleMenu" onClick={() => setMenuVisible(!menuVisible)}>
        <Icon name="arrow-left" size={12} color="#fff"/>
        <Icon name="arrow-left" size={12} color="#fff"/>
      </div>
    </aside>

    <section className="container">
      {children}
    </section>
  </>
}

export default LoggedIn;
