import React from 'react';

const Guest = ({children}) => <div className="row">
    <div className="col jutify-content-center align-items-center d-flex-column login-banner">
        <h1>Control de Jornada Laboral</h1>
        <p style={{ color: '#fff'}}>Cumplir con la ley de registro de jornada laboral y evitar sanciones es fácil y gratis</p>
    </div>
    <div className="col jutify-content-center align-items-center d-flex-column login-form">
        {children}
    </div>
</div>

export default Guest;
