import React from 'react';
import {Platform, Text, View} from "react-native";

const Mensaje = ({message}) => {

    if (!message) return null;

    return <View style={{alignItems: 'center'}}>
        <View style={{
            borderRadius: 8,
            padding: 8,
            textAlign: 'center',
            width: Platform.OS === 'web' ? '100%' : '80%',
            backgroundColor: '#fff3cd',
            marginTop: Platform.OS === 'web' ? 20 : 0,
        }}>
            <Text style={{textAlign: 'center'}}>Mensaje de tu empresa: {message}</Text>
        </View>
    </View>;
}

export default Mensaje;
