import React, {useState, useEffect} from 'react';
import {Button} from 'react-native';
import {TimePicker} from 'react-input-moment';
import moment from 'moment';
require('react-input-moment/css/input-moment.min.css');

export default function dateTimePicker(props) {

	const [time, setTime] = useState(moment(props.date));


	const handleChange = (e) => {
		setTime(e);
		props.onChange(e, e.toDate())
	}


	return (
		<>
			<TimePicker
				moment={time}
				onChange={handleChange}
				showSeconds={false}
				locale="en"
			/>

		</>
	);

}
