import React, {useEffect, useState} from 'react';
import Component from './Component';
import PropTypes from 'prop-types';
import Alert from "../Alert";
import { ActivityIndicator } from 'react-native';
import util from './util';

const IniciarDetenerJornada = (props) => {

    const [stateData, setStateData] = useState({
        loading: true,
        working: false,
        workingStartTime: '',
        isPaused: false,
        pausas: [],
        timeTotalGh: null,
        hasGh: false

    });

    const accessToken = props.token ? props.token : localStorage.access_token;

    useEffect(() => {

        let isMounted = true;

        util.comprobarEstadoTrabajando(accessToken)
          .then(res => {
              if (isMounted){
                  // loading, esta_trabajando, tiempo_inicio, pausa, pausas, tiempo_total_gh, tiene_gh
                  const [loading, working, workingStartTime, isPaused, pausas, timeTotalGh, hasGh ] = res;

                  setStateData({ loading, working, workingStartTime, isPaused, pausas, timeTotalGh, hasGh } );
              }

          })
          .catch(error => {
              console.log(error)
              Alert('Error', 'Error accediendo al servidor en web');
          });

        return () => {
            isMounted = false;
        };

    }, []);


    const {loading, working, workingStartTime, isPaused} = stateData;


    if (loading)
        return <ActivityIndicator />;

    return  <div style={{position: 'relative', zIndex: 1}}>
        <Component {...{working, workingStartTime, isPaused, ...props}} />
    </div>

};

IniciarDetenerJornada.propTypes = {
    trabajadorId: PropTypes.number,
    token: PropTypes.string
};

IniciarDetenerJornada.defaultProps = {
    trabajadorId: null,
};

export default IniciarDetenerJornada;
