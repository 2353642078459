import {API_URL, EPS} from "../constants/Constants";
import axios from "axios";

/**
 * Devuelve una promesa que va a devolver a una cadena con el mensaje
 * o a false en caso de que no haya mensaje o de que este esté fuera de fechas
 * @returns {Promise.Promise} string | bool
 */
const getMessage = async (accessToken) => {

    return new Promise((resolve, reject) => {

        // console.log('calling: ' + API_URL + EPS.EMPRESA_MENSAJE)
        const config = {
            headers: {Authorization: 'Bearer ' + accessToken},
        };

        axios.get(
            API_URL + EPS.EMPRESA_MENSAJE,
            config,
        )
            .then(data => resolve(data.data.data))
            .catch(error => reject(error))
    })

}

export default {
    getMessage
}
