import React from 'react';
import style from "./maskednumber.module.css";

const MaskedNumber = ({ value, onDelete }) => <div className={style.wrapper}>

    <div className={style.content}>
        { [...Array(value.length)].map((a, i) => <span key={i} className={style.value}>*</span>) }
    </div>




    {value.length > 0 && <svg version="1.1" id="Capa_1"  x="0px" y="0px"
         width="30px" height="30px" viewBox="0 0 612 612" className={style.delete} onClick={onDelete}>
    <g>


	<g id="backspace">
		<path d="M561,76.5H178.5c-17.85,0-30.6,7.65-40.8,22.95L0,306l137.7,206.55c10.2,12.75,22.95,22.95,40.8,22.95H561
			c28.05,0,51-22.95,51-51v-357C612,99.45,589.05,76.5,561,76.5z M484.5,397.8l-35.7,35.7L357,341.7l-91.8,91.8l-35.7-35.7
			l91.8-91.8l-91.8-91.8l35.7-35.7l91.8,91.8l91.8-91.8l35.7,35.7L392.7,306L484.5,397.8z" style={{fill: 'rgba(255, 255, 255, .8)'}}/>
	        </g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g>
    </svg>}

</div>;

export default MaskedNumber;
