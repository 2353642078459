import React, { useContext, useEffect, useRef, useState } from 'react';
import style from './pinpage.module.css';
import PinButton from "./PinButton";
import MaskedNumber from "./MaskedNumber";
import UserService from "common/src/services/UserService";
import IniciarDetenerJornada from "common/src/components/IniciarDetenerJornada";
import Alert from "common/src/components/Alert";
import { AppContext } from "common/src/Context/AppContext";
import PinFooter from "./PinFooter";

export const MAX_PIN_LENGTH = 4;
const PinPage = props => {

    const AP = useContext(AppContext)
    const [currentNumber, setCurrentNumber] = useState('');
    const [pinUserData, setPinUserData] = useState(null);

    const wrapperRef = useRef()
    const onNumberSelected = number => {
        setCurrentNumber(currentNumber + number.toString())
        // console.log(number);
        wrapperRef.current.focus()
    }

    useEffect(() => {
        if (!pinUserData) return;
        AP.setPinWebcamVisible(true);
        // console.log('useEffect pinUserData', pinUserData);
    }, [pinUserData]);

    useEffect(() => {
        // console.log('useEffect PinPage AP.lastOnIniciarDetenerDoneTime', AP.lastOnIniciarDetenerDoneTime )
        if (AP.lastOnIniciarDetenerDoneTime.length === 0) return;
        setCurrentNumber('')
        setPinUserData(null)
    }, [AP.lastOnIniciarDetenerDoneTime])

    useEffect(() => {
        async function loadUser() {
            // console.log('currentNumber effect')
            const data = await UserService.findByPin(localStorage.access_token, currentNumber)
            // console.log(data);
            if (data.data?.message && data.data?.message === 'No data') {
                Alert('Pin incorrecto', 'El pin que ha introducido no es correcto. Por favor, inténtelo de nuevo.')
                setCurrentNumber('')
            }
            else {
                setPinUserData(data)
                AP.setLastUserData(data)
            }


        }

        if (currentNumber.length === MAX_PIN_LENGTH)
            loadUser()

    }, [currentNumber])

    const onDelete = () => {
        setCurrentNumber(currentNumber.substr(0, currentNumber.length - 1))
    }

    const onCancelPinButton = () => {
        setPinUserData(null)
        setCurrentNumber('')
    }

    const empresaData = JSON.parse(localStorage.getItem("empresaData"));

    if (!empresaData.esta_activa)
        return <div className={style.wrapper} style={{justifyContent: "center", alignItems: "center"}} ref={wrapperRef}>
            <p style={{ border: "1px solid #fafafa", padding: "20px 25px", color: "#fafafa" }}>La empresa no se encuentra activa.<br />Por favor, contacta con la persona responsable.</p>
        </div>

    return <div className={style.wrapper} ref={wrapperRef}>

        {currentNumber.length < MAX_PIN_LENGTH && <>

            <MaskedNumber value={currentNumber} onDelete={onDelete} />

            <div className={style.row}>
                <PinButton key={1} number={1} onNumberSelected={() => onNumberSelected(1)} />
                <PinButton key={2} number={2} onNumberSelected={() => onNumberSelected(2)} />
                <PinButton key={3} number={3} onNumberSelected={() => onNumberSelected(3)} />
            </div>

            <div className={style.row}>
                <PinButton key={4} number={4} onNumberSelected={() => onNumberSelected(4)} />
                <PinButton key={5} number={5} onNumberSelected={() => onNumberSelected(5)} />
                <PinButton key={6} number={6} onNumberSelected={() => onNumberSelected(6)} />
            </div>

            <div className={style.row}>
                <PinButton key={7} number={7} onNumberSelected={() => onNumberSelected(7)} />
                <PinButton key={8} number={8} onNumberSelected={() => onNumberSelected(8)} />
                <PinButton key={9} number={9} onNumberSelected={() => onNumberSelected(9)} />
            </div>

            <div className={style.row}>
                <PinButton key={0} number={0} onNumberSelected={() => onNumberSelected(0)} />
            </div>
        </>}

        {currentNumber.length === MAX_PIN_LENGTH && pinUserData && <>
            <p className={style.welcomeUser}>Hola, {pinUserData.nombre}</p>
            <div className={style.buttons}>

                {pinUserData && <IniciarDetenerJornada onCancelPinButton={onCancelPinButton} token={pinUserData.token} />}

                <button className={style.buttonKo} onClick={() => {
                    onCancelPinButton();
                }}>No soy {pinUserData.nombre}</button>
            </div>
        </>}

        {currentNumber.length === MAX_PIN_LENGTH && !pinUserData && <p style={{ color: 'white', textAlign: "center" }}>Cargando</p>}

        <PinFooter />

    </div>
}

export default PinPage;
