import React from 'react';

const CenteredLayout = props => <div className="wrapper-centered align-items-center jutify-content-center">
    <div className="wrapper-centered-content">
        <h1>{props.title}</h1>

        <div className="content">
            {props.children}
        </div>
    </div>
</div>;

export default CenteredLayout;
