import React, {useState} from "react";
import SelectDateTime from "../SelectDateTime";


export const CustomDateTimePicker = ({
   date,
   isTypeCalendar,
   changeDate,
   handleClose,
 }) => {

  const [validNewDate, setValidNewDate] = useState()

  return <div>
      {isTypeCalendar ? (
        <div>
          <input
            type="date"
            style={{
              boxSizing: "border-box",
              marginTop: 20,
              width: "100%",
              padding: 6
            }}
            onChange={e => {
              const { value } = e.target;
              setValidNewDate(value)
            }}
          />
          <div className="row">
            <button
              disabled={!validNewDate}
              onClick={() => changeDate(validNewDate)}
              className="btn-primary"
              style={{ width: "100%", marginRight: "2px" }}
            >
              Aceptar
            </button>
            <button
              onClick={handleClose}
              className="btn-cancel"
              style={{ width: "100%", marginLeft: "2px" }}
            >
              Cancelar
            </button>
          </div>
        </div>
      ) : (
        <SelectDateTime
          date={date}
          display="spinner"
          setDate={changeDate}
          cerrarSelectDateTime={handleClose}
        />
      )}
    </div>;
};


