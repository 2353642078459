import {API_URL, EPS} from "../../constants/Constants";
import axios from 'axios';

/**
 *
 * Helper que devuelve una promesa con loading, working y workingStartTime
 *
 * @param {string} token - El token del usuario
 * @param {number|string|null} trabajadorId - El id del trabajador
 * @returns {Promise<unknown>}
 */
const comprobarEstadoTrabajando = (token, trabajadorId = null) => {
    return new Promise((resolve, reject) => {

        trabajadorId = trabajadorId !== null ? '?id=' + trabajadorId : '';
        const config = {
            headers: {Authorization: 'Bearer ' + token},
        };
        axios
            .post(API_URL + EPS.USER_TRABAJANDO + trabajadorId, {}, config)
            .then(data => {
                // loading, esta_trabajando, tiempo_inicio, pausa, pausas, tiempo_total_gh, tiene_gh
                const resData = data.data;
                const res = [false, resData.esta_trabajando, resData.tiempo_inicio, resData.pausa, resData.pausas, resData.tiempo_total_gh, resData.tiene_gh];
                resolve(res);
            })
            .catch(error => {
                console.log(
                    'Error en la petición a: ' +
                    API_URL +
                    EPS.USER_TRABAJANDO +
                    trabajadorId,
                );
                reject([false, false, null, false, null, null, null]);
            });
    });

};


const convertDate = date => {
    function pad(s) {
        return s < 10 ? '0' + s : s;
    }

    return (
      [pad(date.getDate()), pad(date.getMonth() + 1), date.getFullYear()].join(
        '-',
      ) +
      ' ' +
      [pad(date.getHours()), pad(date.getMinutes()), pad(date.getSeconds())].join(
        ':',
      )
    );
};



export default {
    comprobarEstadoTrabajando,
    convertDate
}
