import React, { useEffect } from "react";
import ReactGA from "react-ga4";




const GAEnabled = !__DEV__;

if (GAEnabled){
    ReactGA.initialize("G-87QBXZ1XQS", {
        gtagOptions: {
            send_page_view: false
        }
    });
}

const withGaTracker = (WrappedComponent, options = {}) => {
    const trackPage = page => {
        // console.log(`analyticsTrackScreenView ${JSON.stringify({page})}`);

        if (!GAEnabled){
            console.log('Ignorado porque GAEnabled es false');
            return;
        }

        ReactGA.send({ hitType: "pageview" });
    };

    const HOC = props => {
        useEffect(() => {
            trackPage(props.location.pathname)
          }, [props.location.pathname]);

        return <WrappedComponent {...props} />;
    };

    return HOC;
};

export  default  withGaTracker;


export function analyticsLogInUser(user, company, trackLogin = false) {
    // console.log(`analyticsLogInUser`, user, company);

    if (!GAEnabled){
        console.log('Ignorado porque GAEnabled es false');
        return;
    }
    if (trackLogin) ReactGA.event('login', { method: 'webApp' });

    const properties = {
        userId: user.id.toString(),
        user_properties : { tenantId: company.empresa_id.toString() }
    }

    // console.log(`analyticsLogInUser.settingUserProperties`, properties);

    ReactGA.set(properties)

}

/*
 * stop_working >> component.js
 * start_working >> component.js
 *
 */
export function analyticsTrackEvent(event_name, data = {}) {
    // console.log(`analyticsTrackEvent ${event_name}`, data);

    if (!GAEnabled){
        console.log('Ignorado porque GAEnabled es false');
        return;
    }

    ReactGA.event(event_name, data);
}
